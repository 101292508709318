import { useParams } from 'react-router-dom';
import { GameModeActivity, getSoloModeActivityData, SoloModePuzzle } from './config/solo-mode-activity-config';
import { completedPlaylists } from 'src/fractions/puzzle-local-storage';
import { SoloModeTitle } from './components/title';
import Puzzle from './components/puzzle';
import { SoloModeGoBack } from './components/button';
import { DuckFractionsSoloPageView, trackEvent } from '@puzzles/core/amplitude';
import { useEffect } from 'react';
import './solo-mode-hub.scss';

export const SoloModeHub = () => {
  const { activity } = useParams();
  const gameModeActivity: GameModeActivity = getSoloModeActivityData(activity);
  const soloModePuzzles: SoloModePuzzle[] = gameModeActivity.puzzles;
  const unlockedPlaylistsIndex: number = completedPlaylists()?.length ?? 0;

  useEffect(() => {
    const handleLoad = () => {
      trackEvent({ eventName: DuckFractionsSoloPageView });
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  if (!gameModeActivity) {
    return null;
  }

  return (
    <div className="solo-mode-wrapper" style={{ backgroundImage: `url(${gameModeActivity.background})` }}>
      <SoloModeGoBack activity={activity} />

      <SoloModeTitle title={gameModeActivity.title} />

      <div className="solo-mode-puzzles-wrapper">
        {soloModePuzzles.map((puzzle, index) => (
          <Puzzle
            puzzle={puzzle}
            key={index}
            status={index <= unlockedPlaylistsIndex}
            completedPlaylistArray={completedPlaylists() ?? []}
          />
        ))}
      </div>
    </div>
  );
};
