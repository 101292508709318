import React from 'react';
import { Link } from 'react-router-dom';

interface SoloModeGoBackProps {
  activity?: string | '';
}

export const SoloModeGoBack: React.FC<SoloModeGoBackProps> = ({ activity }) => {
  return (
    <Link to={activity ? `/start/${activity}` : ''} className="solo-mode-goBack">
      Back
    </Link>
  );
};
