import { useEffect, useCallback } from 'react';
import './puzzle-main-hub.scss';
import { HubGameDetails } from './components/hub-game-details';
import equations from '/multiplayer/features/puzzle-main-hub/equations-card.png';
import FAQ from '/multiplayer/features/puzzle-main-hub/FAQ_btn.png';
import contact_us from '/multiplayer/features/puzzle-main-hub/contact_us_btn.png';
import { puzzlesMainHub } from './config/puzzle-main-hub-config';
import { HostMainHubPageView, trackEvent } from '@puzzles/core/amplitude';

export const PuzzleMainHub = () => {
  const onLoad = useCallback((event: any) => {
    console.log(event);
  }, []);

  const handleLoad = () => {
    window.onload = () => {
      trackEvent({ eventName: HostMainHubPageView });
    };
  };

  const handleCleanUp = () => {
    window.removeEventListener('load', onLoad);
  };

  useEffect(() => {
    handleLoad();

    return () => {
      handleCleanUp();
    };
  }, [onLoad]);

  return (
    <div className="hub">
      <div className="hub-header">
        <div className="beta-band">
          <span>Beta</span>
        </div>
      </div>

      <div className="hub-games">
        <h1 className="hub-title">
          One class, one goal,<br></br>many individual learning journeys
        </h1>

        <div className="hub-games-container">
          {puzzlesMainHub.map((game, index) => {
            return <HubGameDetails game={game} key={index} />;
          })}
          <img src={equations} alt="Game equations soon" className="game" loading="lazy" />
        </div>
      </div>

      <div className="hub-footer">
        <div className="hub-footer-btn">
          <a href="https://support.kahoot.com/hc/en-us/articles/15007922059795" title="FAQ Kahoot">
            <img src={FAQ} alt="FAQ" />
            <span>FAQ</span>
          </a>

          <a href="https://kahoot.com/contact-sales/#support" title="contact us Kahoot">
            <img src={contact_us} alt="contact us Kahoot" />
            <span>Contact us</span>
          </a>
        </div>

        <div className="hub-footer-logo"></div>
      </div>
    </div>
  );
};
