import fractionsSoloBackground from '../assets/bg_fractions_hub.jpg';
import fractionsSolo1 from '../assets/fractions-solo-2.webp';
import fractionsSolo2 from '../assets/fractions-solo-1.webp';
import fractionsSolo3 from '../assets/fractions-solo-3.webp';
import fractionsSolo4 from '../assets/fractions-solo-4.webp';
import fractionsSolo5 from '../assets/fractions-solo-5.webp';
import fractionsSolo6 from '../assets/fractions-solo-6.webp';
import fractionsSolo7 from '../assets/fractions-solo-8.webp';
import fractionsSolo8 from '../assets/fractions-solo-7.webp';
import fractionsSolo9 from '../assets/fractions-solo-9.webp';
import fractionsSolo10 from '../assets/fractions-solo-10.webp';

export type SoloModePuzzle = {
  playlist: string;
  title: string;
  background: string;
  url: string;
};

export type GameModeActivity = {
  name: string;
  background: string;
  title: string;
  puzzles: SoloModePuzzle[];
};

export const activities: GameModeActivity[] = [
  {
    name: 'fractions',
    background: fractionsSoloBackground,
    title: '<span className="nowrap">Duck-Duck</span> Fractions',
    puzzles: [
      {
        playlist: 'solo-whole-numbers-intro-01',
        title: 'Duck-Duck Fractions 1',
        background: fractionsSolo1,
        url: '/src/fractions/solo/?playlist=solo-whole-numbers-intro-01-session&maxLevel=8',
      },
      {
        playlist: 'solo-whole-numbers-intro-02',
        title: 'Duck-Duck Fractions 2',
        background: fractionsSolo2,
        url: '/src/fractions/solo/?playlist=solo-whole-numbers-intro-02-session&maxLevel=8',
      },
      {
        playlist: 'solo-discovering-fractions-01',
        title: 'Duck-Duck Fractions 3',
        background: fractionsSolo3,
        url: '/src/fractions/solo/?playlist=solo-discovering-fractions-01-session&maxLevel=8',
      },
      {
        playlist: 'solo-discovering-fractions-02',
        title: 'Duck-Duck Fractions 4',
        background: fractionsSolo4,
        url: '/src/fractions/solo/?playlist=solo-discovering-fractions-02-session&maxLevel=8',
      },
      {
        playlist: 'solo-comparing-fractions-01',
        title: 'Duck-Duck Fractions 5',
        background: fractionsSolo5,
        url: '/src/fractions/solo/?playlist=solo-comparing-fractions-01-session&maxLevel=12',
      },
      {
        playlist: 'solo-comparing-fractions-02',
        title: 'Duck-Duck Fractions 6',
        background: fractionsSolo6,
        url: '/src/fractions/solo/?playlist=solo-comparing-fractions-02-session&maxLevel=12',
      },
      {
        playlist: 'solo-over-one-01',
        title: 'Duck-Duck Fractions 7',
        background: fractionsSolo7,
        url: '/src/fractions/solo/?playlist=solo-over-one-01-session&maxLevel=8',
      },
      {
        playlist: 'solo-over-one-02',
        title: 'Duck-Duck Fractions 8',
        background: fractionsSolo8,
        url: '/src/fractions/solo/?playlist=solo-over-one-02-session&maxLevel=8',
      },
      {
        playlist: 'solo-hardcore-01',
        title: 'Duck-Duck Fractions 9',
        background: fractionsSolo9,
        url: '/src/fractions/solo/?playlist=solo-hardcore-01-session&maxLevel=8',
      },
      {
        playlist: 'solo-hardcore-02',
        title: 'Duck-Duck Fractions 10',
        background: fractionsSolo10,
        url: '/src/fractions/solo/?playlist=solo-hardcore-02-session&maxLevel=8',
      },
    ],
  },
];

export function getSoloModeActivityData(activity: string | undefined): GameModeActivity {
  var soloModeActivityData: GameModeActivity = activities.find((item) => item.name === activity) as GameModeActivity;
  if (!soloModeActivityData) throw new Error('No Solo Mode activity found with name ' + activity);
  return soloModeActivityData;
}
